<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        {{ data.item.title }}
      </template>
      <template #cell(control)="data">
        <b-button
          :to="'/sale/documents/view/' + data.item.id"
          variant="primary"
          size="sm"
          class="mr-1"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      search: '',
      fields: [
        {
          key: 'order_number',
          label: 'No',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'customer',
          label: 'Müşteri Adı',
        },
        {
          key: 'counts',
          label: 'Evraklar',
          thClass: 'width-50 text-nowrap text-center',
          tdClass: 'width-50 text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-50 text-nowrap',
          tdClass: 'width-50 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_sale_documents.id_com_sale_orderform AS id',
          'com_sale_orderform.order_number AS order_number',
          'com_customer.name AS customer',
          'com_customer.company_name AS company',
          'count(com_sale_documents.id) AS counts',
        ],
        group_by: 'com_sale_documents.id_com_sale_orderform',
        limit: 50,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['saleDocuments/dataList']
    },
    dataCount() {
      return this.$store.getters['saleDocuments/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['saleDocuments/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 50
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('saleDocuments/getDataList', this.dataQuery)
    },
  },
}
</script>
